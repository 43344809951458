<template>
  <div class="wrapper blog-posts">
    <div class="page-header page-header-small">
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">
              お知らせ
            </h2>
            <h6>
              News
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <div class="section">
          <h3 class="title text-center">
            2022/07
          </h3>
          <br>
          <div class="row">
            <div class="col-md-4">
              <card
                type="blog"
                plain
              >
                <a href="/#/news-posts/news20220701p1">
                  <img
                    slot="image"
                    class="img rounded img-raised"
                    src="img/bg5.jpg">
                </a>
                <h6 class="category">
                  2022/07/01
                </h6>
                <h4 class="card-title">
                  <a href="/#/news-posts/news20220701p1">テスト投稿1</a>
                </h4>
              </card>
            </div>
            <div class="col-md-4">
              <card
                  type="blog"
                  plain
              >
                <a href="/#/news-posts/news20220701p1">
                  <img
                      slot="image"
                      class="img rounded img-raised"
                      src="img/bg5.jpg">
                </a>
                <h6 class="category">
                  2022/07/01
                </h6>
                <h4 class="card-title">
                  <a href="/#/news-posts/news20220701p1">テスト投稿1</a>
                </h4>
              </card>
            </div>
            <div class="col-md-4">
              <card
                  type="blog"
                  plain
              >
                <a href="/#/news-posts/news20220701p1">
                  <img
                      slot="image"
                      class="img rounded img-raised"
                      src="img/bg5.jpg">
                </a>
                <h6 class="category">
                  2022/07/01
                </h6>
                <h4 class="card-title">
                  <a href="/#/news-posts/news20220701p1">テスト投稿1</a>
                </h4>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button, FormGroupInput } from '@/components';

  export default {
    name: 'NewsPosts',
    bodyClass: 'blog-posts',
    components: {
      Card,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data() {
      return {
        year: new Date().getFullYear()
      }
    },
  }
</script>
<style>
</style>
