<template>
  <div class="wrapper">
    <div
      class="page-header page-header-small header-filter"
      filter-color="orange"
    >
      <parallax
        class="page-header-image"
        style="background-image: url('img/AdobeStock_159363813.jpeg')"
      />
      <div class="container">
        <div class="photo-container">
          <img
            v-lazy="'img/fox-icon.png'"
            alt=""
          >
        </div>
        <h3 class="title">
          八狐　リル
        </h3>
        <p class="category">
          Yako Riru
        </p>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="button-container">
          <a
              href="#button"
              class="btn btn-lg btn-round btn-icon btn-youtube youtube-sharrre sharrre"
              rel="tooltip"
              title="Follow me on Youtube"
          >
            <i class="fab fa-youtube" />
          </a>
          <a
            href="#button"
            class="btn btn-lg btn-round btn-icon btn-twitter twitter-sharrre sharrre"
            rel="tooltip"
            title="Follow me on Twitter"
          >
            <i class="fab fa-twitter" />
          </a>
        </div>
        <h3 class="title">
          自己紹介
        </h3>
        <h6 class="category">
          Self-introduction
        </h6>
        <div class="row">
          <div class="col-md-6">
            <tabs
                pills
                type="primary"
            >
              <tab-pane label="1">
                <img
                    class="img rounded"
                    src="/img/presentation-page/fox-full.png"
                    alt="content 1"
                >
              </tab-pane>
              <tab-pane label="2">
                <img
                    class="img rounded"
                    src="/img/presentation-page/fox-full.png"
                    alt="content 2"
                >
              </tab-pane>
              <tab-pane label="3">
                <img
                    class="img rounded"
                    src="/img/presentation-page/fox-full.png"
                    alt="content 3"
                >
              </tab-pane>
            </tabs>
          </div>
          <div class="col-md-6">
            <h4 class="title text-center">
              八狐　リル
            </h4>
            <h6 class="category">
              Yako Riru
            </h6>
            <info-section
                type="warning"
                title="※sample「もぐもぐ～おかゆ～！」"
                description="※sample おにぎり屋さんを経営してるおばあさんに飼われてる猫。おばあちゃんの部屋にあるPCから配信している。"
                srcfile="https://hololive.hololivepro.com/wp-content/uploads/2021/05/g_おかゆ.mp4"
            />

          </div>
        </div>




      </div>
    </div>
  </div>
</template>
<script>
  import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox } from '@/components';

  export default {
    name: 'ProfilePage',
    bodyClass: 'profile-page',
    components: {
      InfoSection,
      Card,
      Tabs,
      TabPane,
      Badge,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox,
      [FormGroupInput.name]: FormGroupInput,
    }
  }
</script>
<style>
</style>
