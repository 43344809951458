<template>
  <footer
    class="footer"
    :class="{[`footer-${type}`]: type}"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="footer-logo">
          <a href="/"><img src="common/img/top/logo_trillionstage.png" alt="TRILLIONSTAGE"></a>
      </div>
      <nav>
        <ul>
          <li>
            <a href="/#/presentation#news">
              News
            </a>
          </li>
          <li>
            <a href="/#/presentation#about">
              About
            </a>
          </li>
          <!--li>
            <a href="/#/talents">
              Talent
            </a>
          </li>
          <li>
            <a href="#">
              Schedule
            </a>
          </li>-->
          <li>
            <a href="/#/contact">
              Contact
            </a>
          </li>
          <!--<li>
            <a href="https://hololive.booth.pm/">
              Goods
            </a>
          </li> -->
          <li>
            <a href="/#/about/privacy">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/#/audition">
              Audition
            </a>
          </li>
          <li>
            <a href="/#/subscribeUs">
              Subscribe Us
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, Produced by CosmicCreators株式会社
        &copy; {{ year }}, Designed by sawabi.IS.
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    props: {
      backgroundColor: String,
      type: String
    },
    data(){
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>
<style>
</style>
