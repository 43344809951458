<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg24.jpg')"
      />
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">
              お知らせ
            </h2>
            <h6>News</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h6 class="category">
                2020/07/01
              </h6>
              <h3 class="title">
                テスト投稿1
              </h3>
              <p>
                This is the paragraph where you can write more details about your product. Keep you user
                engaged by providing meaningful information. Remember that by this time, the user is
                curious, otherwise he wouldn't scroll to get here. Add a button if you want the user to
                see more. We are here to make life better.
                <br>
                <br> And now I look and look around and there’s so many Kanyes I've been trying to
                figure out the bed design for the master bedroom at our Hidden Hills compound... and
                thank you for turning my personal jean jacket into a couture piece.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-blog col-md-10 ml-auto mr-auto">
        <div class="container">
          <div class="section-story-overview">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="image-container image-left"
                  style="background-image: url('img/bg35.jpg')"
                >
                  <!-- First image on the left side -->
                  <p class="blockquote blockquote-primary">
                    "Over the span of the satellite record,
                    Arctic sea ice has been declining significantly, while sea ice in the
                    Antarctichas increased very slightly"
                    <br>
                    <br>
                    <small>-NOAA</small>
                  </p>
                </div>
                <!-- Second image on the left side of the article -->
                <div
                  class="image-container image-left-bottom"
                  style="background-image: url('img/bg29.jpg')"
                />
              </div>
              <div class="col-md-5">
                <!-- First image on the right side, above the article -->
                <div
                  class="image-container image-right"
                  style="background-image: url('img/project16.jpg')"
                />
                <h3>So what does the new record for the lowest level of winter ice actually mean</h3>
                <p>
                  The Arctic Ocean freezes every winter and much of the sea-ice then thaws every
                  summer, and that process will continue whatever happens with climate change. Even if
                  the Arctic continues to be one of the fastest-warming regions of the world, it will
                  always be plunged into bitterly cold polar dark every winter. And year-by-year, for
                  all kinds of natural reasons, there’s huge variety of the state of the ice.
                </p>
                <p>
                  For a start, it does not automatically follow that a record amount of ice will melt
                  this summer. More important for determining the size of the annual thaw is the state
                  of the weather as the midnight sun approaches and temperatures rise. But over the
                  more than 30 years of satellite records, scientists have observed a clear pattern of
                  decline, decade-by-decade.
                </p>
                <p>
                  The Arctic Ocean freezes every winter and much of the sea-ice then thaws every
                  summer, and that process will continue whatever happens with climate change. Even if
                  the Arctic continues to be one of the fastest-warming regions of the world, it will
                  always be plunged into bitterly cold polar dark every winter. And year-by-year, for
                  all kinds of natural reasons, there’s huge variety of the state of the ice.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section-blog-info">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <div class="row">
                <div class="col-md-6">
                  <div class="blog-tags">
                    Tags:
                    <span class="label label-primary">#Member </span>
                    <span class="label label-primary">#PR </span>
                  </div>
                </div>
              </div>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button, Comment, Checkbox } from '@/components';

  export default {
    name: 'BlogPost',
    bodyClass: 'blog-post',
    components: {
      Card,
      Comment,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox
    },
    data() {
      return {
        form: {
          comment: ''
        },
      }
    }
  }
</script>
<style>
</style>
