<template>
  <div :style="styles">
    <slot />
  </div>
</template>
<script>
  function debounce(func, wait, immediate) {
    let timeout;
    return function () {
      const context = this,
        args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        if (!immediate) func.apply(context, args);
      }, wait);
      if (immediate && !timeout) func.apply(context, args);
    };
  }
  export default {
    name: 'SectionParallax',
    data() {
      return {
        styles: {},
        debounceTimeout: 6
      }
    },
    mounted() {
      let self = this;
      window.addEventListener("scroll", function () {
        let scrollVal = this.scrollY;
        let rect = this.DOMRect;
        self.checkForParallax(scrollVal, rect);
      })
    },
    methods: {
      handleScroll(scrollVal, topVal) {
        console.log("botVal:"+ topVal)
        console.log("scrollVal:"+scrollVal)
        console.log(scrollVal+ topVal)
        let oVal = (scrollVal / 3) - 600;
        this.styles = {
          transform: `translate3d(0, ${oVal}px,0)`
        };
      },
      checkForParallax(scrollVal, topVal) {
        let fn = debounce(() => this.handleScroll(scrollVal, topVal), this.debounceTimeout);
        fn();
      }
    }
  }
</script>
