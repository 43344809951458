import {Parallax} from '../components';
import SectionParallax from "../components/SectionParallax";
/**
 * You can register global components here
 */

const globalComponents = {
  install (Vue) {
    Vue.component(Parallax.name, Parallax)
    Vue.component(SectionParallax.name, SectionParallax)
  }
}

export default globalComponents
