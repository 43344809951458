import Vue from "vue";
import Router from "vue-router";
import Presentation from "./pages/Presentation.vue";
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";
import NewsPosts from "./pages/news/NewsPosts.vue";
import Talents from "./pages/talents/Talents.vue";
import ProfileYakoriru from "./pages/talents/ProfileYakoriru.vue";
import News20220701P1 from "./pages/news/News20220701P1.vue";
import Contact from "./pages/contact/Contact.vue";
import SubscribeUs from "./pages/subscribeUs/SubscribeUs.vue";
import Privacy from "./pages/about/Privacy";
import Audition from "./pages/audition/Audition.vue"


Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/presentation"
    },
    {
      path: "/presentation",
      name: "presentation",
      components: {default: Presentation, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 500}}
    },
    {
      path: "/contact",
      name: "contact",
      components: {default: Contact, header: MainNavbar, footer: MainFooter},
      props: {footer: {type: 'default'}}
    },
    {
      path: "/subscribeUs",
      name: "subscribeUs",
      components: {default: SubscribeUs, header: MainNavbar, footer: MainFooter},
      props: {footer: {type: 'default'}}
    },
    {
      path: "/about/privacy",
      name: "privacy",
      components: {default: Privacy, header: MainNavbar, footer: MainFooter},
      props: {footer: {type: 'default'}}
    },
    {
      path: "/news-posts",
      name: "news-posts",
      components: {default: NewsPosts, header: MainNavbar},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/news-posts/news20220701p1",
      name: "news20220701p1",
      components: {default: News20220701P1, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/talents",
      name: "talents",
      components: {default: Talents, header: MainNavbar},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/talents/yakoriru",
      name: "yakoriru",
      components: {default: ProfileYakoriru, header: MainNavbar},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/audition",
      name: "audition",
      components: {default: Audition, header: MainNavbar, footer: MainFooter},
      props: {footer: {type: 'default'}}
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
});

export default router;
