<template>
  <div class="wrapper bg_base base_container">
        <div class="container p-contact">
          <div class="content-center">
            <div class="row base_title">
              <div class="col-md-8 text-center ml-auto mr-auto">
                <h2 class="title">
                  SUBSCRIBE US
                </h2>
                <h3>
                  サブスク
                </h3>
              </div>
            </div>
          </div>
          <div class="content_frame">
            <div>
              <h4 class="text-left">
                Googleログイン
              </h4>
              <p>チャンネル登録後にログインを実施すると、配布コンテンツを受け取ることが出来ます。</p>
              <ul>
                <li>
                  <p></p>
                </li>
               </ul>
              <ul class="contact-attention">
                <li>Googleログインは、下記用途に利用しています。</li>
                <li>要求権限</li>
                <li>・Youtubeの登録チャンネル読み込み</li>
                <li><br></li>
                <li>使用目的</li>
                <li>・Youtubeチャンネル登録状態の確認</li>
                <li>・<a href="#/about/privacy">プライバシーポリシー</a></li>
              </ul>
            </div>
            <a href="https://api.trillionstage.jp/download/trillionstage"><img src="common/img/top/btn_google_signin_light_normal_web@2x.png" alt="google-login"></a>
            <p class="text-right"><img src="common/img/top/developed-with-youtube-sentence-case-light.png"></p>
          </div>
        </div>
  </div>
</template>
<script>
  import { Card, Button, Comment, Checkbox } from '@/components';

  export default {
    name: 'BlogPost',
    bodyClass: 'blog-post',
    components: {
      Card,
      Comment,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox
    },
    data() {
      return {
        form: {
          comment: ''
        },
      }
    }
  }
</script>
<style>
</style>
