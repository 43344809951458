<template>
  <div class="wrapper bg_base base_container">
        <div class="container p-contact">
          <div class="content-center">
            <div class="row base_title">
              <div class="col-md-8 text-center ml-auto mr-auto">
                <h2 class="title">
                  CONTACT
                </h2>
                <h3>
                  お問い合わせ
                </h3>
              </div>
            </div>
          </div>
          <div class="content_frame">
            <div>
              <h4 class="text-left">
                Q. 所属タレントへプレゼントを送りたい
              </h4>
              <ul>
                <li>
                  <p>A. TRILLIONSTAGE所属タレントへのプレゼントは下記へお送りください。
                <br>
                ※タレントへの直接のお渡しはご遠慮頂いております。</p>
                </li>
               </ul>
              <ul class="contact-attention">
                <li>プレゼント送付先</li>
                <li>・電子媒体</li>
                <li style="padding-left: 30px;">メール： contact@trillionstage.jp</li>
                <li>・物品</li>
                <li style="padding-left: 30px;">Prebo: <a href="https://bitstar.tokyo/prebo/trillionst/senders/top">送付先システム</a></li>
                <li><br></li>
                <li>※品名、宛先タレント名、送り主様のお名前（ハンドルネーム等）も一緒に明記するよう、お願いいたします。</li>
              </ul>
              <ul class="contact-attention">
                <li>注意事項（2022/2/20）</li>
                <li>・お預かりしたプレゼントは、内容をご確認させて頂いた上で運営の判断によりお渡しできない場合がございます</li>
                <li>・お預かりすることができないと判断されたものに関しては、適宜破棄させていただいております。</li>
                <li>・プレゼントのお預かり状況につきましては原則お答えしておりません。</li>
                <li>・お預かりしたプレゼントは、いかなる場合も返送することが出来ません。</li>
                <li>・プレゼントに関して、「○○は送れますか？」等のお問い合わせについては、お答えいたしかねます。</li>
                <li>上記ご了承の上、送付のほどお願いいたします。</li>
                <br>
                <li>原則としてプレゼントとしてお預かりすることが出来ない物</li>
                <li>・キャストへ直接送付したもの</li>
                <li>・使用済み/開封済みの物全て</li>
                <li>・高額商品 (1点の価格が1万円前後を超える商品)</li>
                <li>・生モノ (消費期限が概ね１年未満のもの)</li>
                <li>・生物</li>
                <li>・飲食物</li>
                <li>・現金・配信活動に関係のない使途不明の金券等</li>
                <li>・医薬品</li>
                <li>・宗教および政治的・思想的な主張が含まれるもの</li>
                <li>・タレントが一人で持ち帰ることが出来ない物（重さのあるもの、大きいものなど）</li>
                <li>・公序良俗に反するもの、各種法令に抵触する可能性があるもの</li>
                <li>・その他、運営がお預かり出来ないと判断したもの</li>
              </ul>
            </div>
            <div>            
              <h4 class="text-left">
                その他
              </h4>
              <ul>
                <li>
                  <p>TRILLIONSTAGEに関するご質問、所属VTuberへのお仕事のご依頼や当社への提携のご相談などはこちらからお問い合わせください。</p>
                </li>
                <li>mail to : contact@trillionstage.jp</li>
              </ul>
            </div>
          </div>
        </div>
  </div>
</template>
<script>
  import { Card, Button, Comment, Checkbox } from '@/components';

  export default {
    name: 'BlogPost',
    bodyClass: 'blog-post',
    components: {
      Card,
      Comment,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox
    },
    data() {
      return {
        form: {
          comment: ''
        },
      }
    }
  }
</script>
<style>
</style>
