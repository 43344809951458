<template>
  <div class="wrapper presentation-page">
    <div>
      <!--<div class="rellax-header rellax-header-sky">
        <parallax
            class="page-header-image"
            style="background-image: url('common/img/top/bg_main.png')"
        />
      </div>
      <div
        class="rellax-header rellax-header-buildings"
        data-rellax-speed="0"
      >
        <parallax
          class="page-header-image page-header-city"
          style="background-image: url('common/img/top/bg_main.png')"
        />
      </div>-->
      <div class="main-container wrapper-bg">
        <!--<h1 class="main-logo">
        <img src="common/img/logo_trillionstage.svg" alt="TRILLIONSTAGE" width="200" height="50">
        </h1>-->
        <div class="main-logo">
          <img src="common/img/top/logo_trillionstage.png" alt="TRILLIONSTAGE">
        </div>

        <div class="main-img">
          <img src="common/img/top/chara_main.png" alt="TRILLIONSTAGE　タレントシルエット">
        </div>
      </div>
    </div>
    <div class="test"></div>
    <div id="about" class="bg_about">
      <div class="container bg_about-box">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
              <h2 class="title">
                ABOUT
              </h2>
              <h3 class="category">
                TRILLIONSTAGEについて
              </h3>
              <img src="img/presentation-page/message.png" alt="夢はでっかく――1兆人に愛を届けたい。">
              <p class="description">
                『TRILLIONSTAGE(トリリオンステージ)』は、あらゆる異世界から「アイドルの卵」を発掘する「アイドルVtuber事務所」です。コンプレックスは武器に変わる、「人と違う所」を活かして輝く女の子たちを全力で応援します。
              </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg_member member-body">
      <div class="container pr-auto">
        <div class="row">
          <div class="col-md-10">
            <h2 class="title">TALENT</h2>
              <h3 class="category">タレント</h3>
          </div>
        </div>
        <div class="row member-box">
          <div class="col-xl-6 pr-auto">
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <img src="common/img/top/chara_01.png" alt="好狐星　レイ">
                    <span>Illustrator：MAIRO</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body member-model01">
                    <h4 class="card-title">好狐星 レイ</h4>
                    <p class="card-title__small">- SUKOBOSHI REI -</p>
                    <div class="card-footer">
                      <a href="https://www.youtube.com/channel/UCNxKbKlIa1BTCq05pp384Mg">
                        <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
                      </a>
                      <a href="https://twitter.com/rei_sukoboshi">
                        <img src="common/img/top/test.svg" alt="Twitter">
                      </a>
                    </div>
                    <h5><span>「あたしはキミたちをすこる、キミたちはあたしをすこる！すこすこ同盟結成！」</span></h5>
                    <div class="accordion">
                        <input id="block-01" type="checkbox" class="toggle">
                        <label class="Label" for="block-01">introduction</label>
                        <div class="content">
                          <p>『白面金毛九尾の末裔』が住まう『九尾の郷』からやってきたギャル。<br>
                            九尾なのに尻尾が八本しかなく、狐火をだそうとすると氷がモリモリでちゃう、落ちこぼれ過ぎて逆に楽しくなってきてギャルになったそうだ。
                            リスナーと一緒に違う景色を見てみたくて、ギャル九尾系アイドルとして爆進中！<br>
                            <br>
                            主食は昆虫。とくにゴキブリには目がない。
                          </p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 pr-auto">
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <img src="common/img/top/chara_02_1.png" alt="羽愛兎　みゅう">
                    <span>Illustrator：生川</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">羽愛兎 みゅう</h4>
                    <p class="card-title__small">- HEART NO MYU -</p>
                    <div class="card-footer">
                      <a href="https://www.youtube.com/channel/UC2Fq5PKMRbTSo3hqhu8u1yw">
                        <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
                      </a>
                      <a href="https://twitter.com/myu_heartno">
                        <img src="common/img/top/test.svg" alt="Twitter">
                      </a>
                    </div>
                    <h5><span>「うみゅー！白ウサギにアイドルは過酷すぎるよ！ボリボリボリ」</span></h5>
                    <div class="accordion">
                        <input id="block-02" type="checkbox" class="toggle">
                        <label class="Label" for="block-02">introduction</label>
                        <div class="content">
                          <p>１億年後の不思議の国『ワワワワンダーランド』からやってきた探検家の白うさぎ。<br>
                            探検隊員を募ってなにやら『不思議』を収集しているようだが──。<br>
                          <br>
                            美味しい人参につられてアイドル契約を結んでしまったため、日々ひぃこら弱音を叫びながらアイドル目指して頑張っている。大人って汚ねぇーボリボリボリボリボリボリ。
                            第一期生のツッコミ兼マスコット担当である。
                          </p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 pr-auto">
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >
                <div class="col-md-5">
                  <div class="card-image">
                      <img src="common/img/top/chara_03_1.png" alt="咲蝶  みくる">
                      <span>Illustrator：氷菜</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">咲蝶  みくる</h4>
                    <p class="card-title__small">- SAKICCHO MIKURU -</p>
                    <div class="card-footer">
                      <a href="https://www.youtube.com/channel/UCv2bd2F-abCJaE7-wruZ6Bg">
                        <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
                      </a>
                      <a href="https://twitter.com/miku_ruuuu">
                        <img src="common/img/top/test.svg" alt="Twitter">
                      </a>
                    </div>
                    <h5><span>「あざとい可愛い大渋滞！全長２センチ☆羽虫界のミクロアイドルでーす！」</span></h5>
                    <div class="accordion">
                        <input id="block-03" type="checkbox" class="toggle">
                        <label class="Label" for="block-03">introduction</label>
                        <div class="content">
                          <p>私みくる！生後４週間！トップアイドルに憧れ「蟲の國」を飛び出したモンシロ蝶！<br>
                            最高のアイドルになりたくて、キラキラ輝く光に惹かれて翔んでたら『トリリオンステージ』に辿り着いちゃった！てへ！ちょっぴり天然さんでおっちょこちょいだけど、トップアイドルに育ててくれるブリーダーさんを募集中だよー！<br>
                            名前は「さきっちょ」って読むの！みんなの「先っちょ」にとまっちゃうぞー！
                            <br>
                            <br>
                            ……ふぅ。
                          </p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 pr-auto">
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <img src="common/img/top/chara_04_1.png" alt="七峰ニナ">
                    <span>Illustrator：Date</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">七峰 ニナ</h4>
                    <p class="card-title__small">- NANAMINE NINA -</p>
                    <div class="card-footer">
                      <a href="https://www.youtube.com/channel/UCJMLq6i8piYf0AClqg8vsfA">
                        <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
                      </a>
                      <a href="https://twitter.com/NanamineNina">
                        <img src="common/img/top/test.svg" alt="Twitter">
                      </a>
                    </div>
                    <h5><span>「将来の夢はかわいい女の子のヒモです。よろしくお願いします。」</span></h5>
                    <div class="accordion">
                      <input id="block-04" type="checkbox" class="toggle">
                      <label class="Label" for="block-04">introduction</label>
                      <div class="content">
                        <p>TRILLIONSTAGE所属、1.5期生の七峰ニナです。<br>
                          配信を中心に、ゲームをしたり、何かを作ったり、雑談したり、みんなと楽しい時間を共有したいです。
                          <br>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 pr-auto">
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <img src="common/img/top/chara_05_1.png" alt="白瀬 スイナ">
                    <span>Illustrator：Date</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">白瀬 スイナ</h4>
                    <p class="card-title__small">- SIRASE SUINA -</p>
                    <div class="card-footer">
                      <a href="https://www.youtube.com/channel/UCgx5BnhXFHxi4RflFV0l3Ig">
                        <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
                      </a>
                      <a href="https://twitter.com/sirasesuina">
                        <img src="common/img/top/test.svg" alt="Twitter">
                      </a>
                    </div>
                    <h5><span>「軍隊やめてニートしてたら白瀬王国を追放されました！なのでアイドルになってやろうと思います😋」</span></h5>
                    <div class="accordion">
                      <input id="block-05" type="checkbox" class="toggle">
                      <label class="Label" for="block-05">introduction</label>
                      <div class="content">
                        <p>TRILLIONSTAGE所属1.5期生の白瀬スイナです！<br>
                          歌とダンスとゲームが大好きです。自慢のアサルトライフルでみんなのハートを撃ち抜くぞ♡
                          <br>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 pr-auto">
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <img src="common/img/top/chara_06_1.png" alt="ViV">
                    <span>Illustrator：kiriko</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">ヴィヴ シルヴィ</h4>
                    <p class="card-title__small">- ViV Silvey -</p>
                    <div class="card-footer">
                      <a href="https://www.youtube.com/@ViV_silvey">
                        <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
                      </a>
                      <a href="https://twitter.com/ViV_silvey">
                        <img src="common/img/top/test.svg" alt="Twitter">
                      </a>
                    </div>
                    <h5><span>「私を討伐してもレア素材とかドロップしないからね？！」</span></h5>
                    <div class="accordion">
                      <input id="block-06" type="checkbox" class="toggle">
                      <label class="Label" for="block-06">introduction</label>
                      <div class="content">
                        <p>前世は剣と魔法の世界に絶対王者として君臨していた、ラスボス「クリスタルドラゴン」<br><br>
                          その透明な息吹は歌となり、蒼穹へ響き渡り天を震わせる。──そんな格好いい一面と、ポンコツを併せ持つらしいが……結局は天才ドラゴンなので大抵のことは万事解決してしまう。奇抜な企画力とクリスタルボイスな歌声で眷属を日夜魅了している。<br>
                          TRILLION STAGE二期生にして、ラスボス系アイドルVTuberユニット「人類滅亡部」に所属している。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 pr-auto">
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <img src="common/img/top/chara_07_1.png" alt="猫滅刃 とばり">
                    <span>Illustrator：ほうき星</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">猫滅刃とばり</h4>
                    <p class="card-title__small">- NYAHOROBA TOBARI -</p>
                    <div class="card-footer">
                      <a href="https://www.youtube.com/@NyahorobaTobari">
                        <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
                      </a>
                      <a href="https://twitter.com/NyahorobaTobari">
                        <img src="common/img/top/test.svg" alt="Twitter">
                      </a>
                    </div>
                    <h5><span>「とばりと『あるじ』との理想郷、『にゃほろば』においでにゃぁ」</span></h5>
                    <div class="accordion">
                      <input id="block-07" type="checkbox" class="toggle">
                      <label class="Label" for="block-07">introduction</label>
                      <div class="content">
                        <p>前世は宇宙の始まり切り開いたとされる伝説の刀、ラスボス「有他種歌歌手時切（うたたねうたかたのとききり）」。<br><br>
                          全知全能にして過去未来現在の因果を掌握する桁違いの存在であった彼女の悩みは、生命と同じ歩幅で歩み喜怒哀楽を共有することだった。誕生から数十億年を経たある日、その願いがついに叶い、故郷とは別の宇宙であるこの地球に転生することに──。途中に、うっかり猫ちゃんが混じっちゃって、いい感じに全知全能は消えてしまったそうだ。<br>
                          TRILLION STAGE二期生にして、ラスボス系アイドルVTuberユニット「人類滅亡部」に所属している。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 pr-auto">
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <img src="common/img/top/chara_08_1.png" alt="アビス・ソング・シンドローム">
                    <span>Illustrator：8イチビ8</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">アビス・ソング・シンドローム</h4>
                    <p class="card-title__small">- Abyss・Song・Syndrome -</p>
                    <div class="card-footer">
                      <a href="https://www.youtube.com/@A_S_Syndrome">
                        <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
                      </a>
                      <a href="https://twitter.com/A_S_Syndrome">
                        <img src="common/img/top/test.svg" alt="Twitter">
                      </a>
                    </div>
                    <h5><span>「おっきいお姉さんは好きですか♥♥♥♥♥😭😭😭😭😭」</span></h5>
                    <div class="accordion">
                      <input id="block-08" type="checkbox" class="toggle">
                      <label class="Label" for="block-08">introduction</label>
                      <div class="content">
                        <p>前世は星のほぼ全てが海でできた惑星に生息する、ラスボス「超巨大惑星クラーケン」。<br><br>
                          彼女は歌が好きだった。だがその巨大な体躯は惑星の半分を覆うほどであり、触手をわずかでも動かせば島が沈没してしまうため、歌うなど以ての外だった。<br>
                          この地球に転生し、胸の奥に秘めていた歌を自由自在に歌える今は、彼女にとって楽園に他ならないだろう。<br>
                          TRILLION STAGE二期生にして、ラスボス系アイドルVTuberユニット「人類滅亡部」に所属している。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 pr-auto">
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <img src="common/img/top/chara_09_1.png" alt="ホロビ・マッドナイト・オイル">
                    <span>Illustrator：sameta</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">ホロビ・マッドナイト・オイル</h4>
                    <p class="card-title__small">- Horobi・Madnight・Oil -</p>
                    <div class="card-footer">
                      <a href="https://www.youtube.com/@horobi_m_o">
                        <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
                      </a>
                      <a href="https://x.com/Horobi_madnight">
                        <img src="common/img/top/test.svg" alt="Twitter">
                      </a>
                    </div>
                    <h5><span>TRILLION STAGE三期生にして、異端系アイドルVTuberユニット「堕落学院」に所属するスプラッタ系Vsinger。</span></h5>
                    <div class="accordion">
                      <input id="block-09" type="checkbox" class="toggle">
                      <label class="Label" for="block-09">introduction</label>
                      <div class="content">
                        <p>常軌を逸した戦闘力を保有し生まれてしまったがために、勢い余って故郷の惑星の生命を全て「みなごろし」にしてしまった過去を持つ、お茶目な狂犬。<br>
                          誇張表現でも設定でもなく血を見るのが好きであり、合法的に他人の血を見る環境を求めていたら、アイドルを目指すことになった。めぐり合わせって大切だね。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 pr-auto">
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <img src="common/img/top/chara_10_1.png" alt="七天八十院アエギス">
                    <span>Illustrator：sageジョー</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">七天八十院アエギス</h4>
                    <p class="card-title__small">- Shichitenbattouin Aegis -</p>
                    <div class="card-footer">
                      <a href="https://www.youtube.com/@AEGIS_shichiten">
                        <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
                      </a>
                      <a href="https://x.com/AEGIS_shichiten">
                        <img src="common/img/top/test.svg" alt="Twitter">
                      </a>
                    </div>
                    <h5><span>TRILLION STAGE三期生にして、異端系アイドルVTuberユニット「堕落学院」に所属する音大首席系オペラVsinger。</span></h5>
                    <div class="accordion">
                      <input id="block-10" type="checkbox" class="toggle">
                      <label class="Label" for="block-10">introduction</label>
                      <div class="content">
                        <p>前世は『愛』を至上価値とする男女比50:1の惑星に君臨する6人の「上級サキュバス」の一人だったが、デュラハンとのハーフという異端性から数万年に渡り封印されていたらしい。<br>
                          規律が厳しい部活動に入っていた高校生が大学で羽目を外すかの如く、アエギスは今、数万年分のハメを外しているのだ。大目に見てやってほしい、いろいろと。<br>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 pr-auto">
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <img src="common/img/top/chara_11_1.png" alt="虚囁">
                    <span>Illustrator：Yan.C</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">虚囁</h4>
                    <p class="card-title__small">- Utsutsu -</p>
                    <div class="card-footer">
                      <a href="https://www.youtube.com/@utsutsu2">
                        <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
                      </a>
                      <a href="https://x.com/utsutsu_sexy">
                        <img src="common/img/top/test.svg" alt="Twitter">
                      </a>
                    </div>
                    <h5><span>TRILLION STAGE三期生にして、異端系アイドルVTuberユニット「堕落学院」に所属するASMR系アイドルVTuber。</span></h5>
                    <div class="accordion">
                      <input id="block-11" type="checkbox" class="toggle">
                      <label class="Label" for="block-11">introduction</label>
                      <div class="content">
                        <p>不死の生物だけが暮らす「死のない惑星」で、唯一死をもたらし永遠を終わらせる「声」をもつウロボロス。耳にするだけで死ぬ「囁き」を持つため、忌避され長い歳月を一人で暮らしていた。<br>
                          だが、彼女はざっくばらんに言えば変態なのだ。自分の声で誰かをぞくぞくさせ、興奮させたいと願う大変なる変態なのだ。<br>
                          その願いが届き、この地球に転生してきた彼女の声は、もう死を齎さない。これが意味するところ、健全なリスナー諸氏ならよく分かるだろう。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>

    <div class="bg_other color-light-gray">
      <div class="container pr-auto">
        <div class="row">
          <div class="col-xl-6 pr-auto">
            <div class="col-md-10 ml-auto mr-auto">
              <h2 class="title">EXECUTIVE<br>PRODUCER</h2>
            </div>
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >

                <div class="col-md-5">
                  <div class="card-image">
                    <img src="common/img/top/producer_01.png" alt="囚人P">
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">囚人P</h4>
                    <p class="card-title__small">- SYUJIN P -</p>
                    <div class="card-footer">
                      <a href="https://www.youtube.com/channel/UCLoRoW174kO9BCLwpEzBkqg">
                        <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
                      </a>
                      <a href="https://twitter.com/syujinp">
                        <img src="common/img/top/test.svg" alt="Twitter">
                      </a>
                    </div>
                    <p class="card-description card-prof">
                      ボカロP、作曲家、小説家、そして、スマホアプリのプランナーなど多方面に渡り活躍し、進化し続けるマルチタレント『囚人P/猫ロ眠』が手掛けるVTuberプロジェクト始動！<br>
                    </p>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 pr-auto">
            <div class="col-md-10 ml-auto mr-auto">
              <h2 class="title">EXECUTIVE<br>ADVISOR</h2>
            </div>
            <card
                type="profile"
                plain
            >
              <div
                  slot="raw-content"
                  class="row anim-box slidein anim is-animated"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <img src="common/img/top/advisor_01.png" alt="竹下和広｜Kazuhiro Takeshita">
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">竹下和広</h4>
                    <p class="card-title__small">- KAZUHIRO TAKESHITA -</p>
                    <div class="card-footer">
                      <a href="https://www.linkedin.com/in/竹下-和広-kaz-takeshita-7930361/">
                        <img src="common/img/top/ico_sns_li.svg" alt="LinkedIn">
                      </a>
                    </div>
                    <p class="card-description card-prof">
                      数々のゲーム会社の要職を歴任、更にメタバース・ビジネスに進出、クリエイターエコノミーの確立を掲げ、囚人Pプロデュースの新規VTuberプロジェクト「TRILLIONSTAGE」に共感し、エグゼクティブ・アドバイザーとして参画<br>
                    </p>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>

    <div  class="color-gray bg_account">
      <div class="container account-body">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h2 class="title">Official account</h2>
            <p class="description">
              公式Twitterアカウントでは、特別番組やイベント、グッズ等についてお知らせいたします。
              <!--公式YouTubeでは、ショートアニメを更新したり、特別番組の生放送を行います。-->
            </p>
            <div class="sns-footer">
              <!--<a href="#pablo">
                <img src="common/img/top/ico_sns_yt.svg" alt="You Tube">
              </a>-->
              <a href="https://twitter.com/1000000000000st">
                <img src="common/img/top/test.svg" alt="Twitter">
              </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { Card, InfoSection } from '@/components';
import { Carousel, CarouselItem, Tooltip } from 'element-ui';
import Rellax from "rellax";

export default {
  name: 'Presentation',
  bodyClass: 'presentation-page',
  components: {
    Card,
    InfoSection,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Tooltip.name]: Tooltip
  },
  mounted() {
    if (window.innerWidth >= 991) {

      setTimeout(function() {
        var rellax = new Rellax('.rellax', {
          center: true
        });
      }, 5000);

      var rellaxHeader = new Rellax('.rellax-header');
      var rellaxText = new Rellax('.rellax-text');
    }

  }
}
</script>

<style scoped lang="scss">
  .github-buttons {
      display: flex;
      align-content: center;
  }
</style>
